

























import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import {
  BFormSelect,
  BFormSelectOption,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BSpinner
} from "bootstrap-vue";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue"
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import Draggable from 'vuedraggable';
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    ValidationProvider,
    BFormDatepicker,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    SearchableVueSelect,
    Draggable,
    RessifnetDateInput,
    BFormCheckbox,
    BSpinner
  },
  name: "preferences-utilisateur-actions-columns",
})
export default class PreferencesUtilisateurToolTip extends Vue {

  get userPreferences() {
    return this.$store.state.user.user_preferences
  }

  get preference() {
    return this.userPreferences.find((value: any) => {
      return value.key == 'DisplayToolTip'
    });
  }

  async updatePreference() {
    await this.$http.myressif.preferencesUtilisateur.put(
      this.preference.id, this.preference
    ).then(async (response: any) => {

      this.$store.dispatch('user/fetchPreferencesUtilisateur');
      successAlert.fire({
        title: 'Modification d\'une preference utilisateur',
        text: 'Modification effectuée avec succès',
      })

    }, (error: any) => {
      errorAlert.fire({
        title: "Erreur lors de la mise à jour de la preference utilisateur",
      });
    });
  }
}
