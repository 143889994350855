



























import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BCard, BAlert, BLink, BCardText, BRow, BCol, BSpinner, BFormFile
} from 'bootstrap-vue'
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
 import PreferencesUtilisateurToolTip from './preferences/_ToolTip.vue'
import store from '@/store/index'
import { TypePreference } from '@/api/models/enums/typePreference';
@Component({
  components: {
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BCardText,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    PreferencesUtilisateurToolTip,
    BFormFile
  }
})
export default class PreferencesUtilisateur extends Vue {
  userPreferences: [] = [];

  async created() {
    this.loadUserPreferences();

  }
  
  async loadUserPreferences() {
    this.userPreferences = store.getters['user/user_preferences'];

      if (!this.userPreferences.length) {
        this.$store.dispatch('user/saveUserPreference', {
          key : "DisplayToolTip",
          stockable : true,
          value : "true",
          type : TypePreference.ToolTip          
        });
    }
  }
}
